import { useEffect } from 'react'
import posthog from 'posthog-js'

export function PosthogInit() {
  useEffect(() => {
    if (!ENV.POST_HOG_API_KEY) return

    const isLocal =
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('127.0.0.1')

    if (!isLocal) {
      posthog.init(ENV.POST_HOG_API_KEY, {
        api_host: 'https://app.posthog.com',
        disable_session_recording: isLocal,
      })
    }
  }, [])
  return null
}
